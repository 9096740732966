:root {
  --screen-xl: 1366px;
  --screen-lg: 1100px;
  --screen-md: 800px;
  --mobile-navbar-height: 48px;
  --navbar-height: 68px;

  --blue: #1976d2;
  --theme-blue: #3f57cb;
  --discord-blue: #5865f2;
  --canva-purple: #8b3dff;
  --dark-purple: #4039a8;
  --bg-light-purple: #f8f1ff;
  --monaco-black: #1e1e1e;
  --text-green: #4fa45d;
  --dark-cyan-blue: #0072b1;
  --dark-mud: #b35a04;
  --deep-red: #cc0621;
  --bg-light-red: #fff1f5;
  --black: #000;
  --red: #ff3b30;
  --white: #fff;
  --bg-light-blue: #dfecff;
  --bg-light-blue2: #f3f8ff;
  --text-dark-orange: #8c4500;
  --theme-yellow: #f3ca20;
  --yellow: #ffcc00;
  --light-yellow: #ffe370;
  --bg-light-yellow: #fff2cc;
  --bg-extra-light-yellow: #fff9e6;
  --bg-light-orange: #fff5e6;
  --white: #ffffff;
  --bg-light: #dadedf36;
  --grey: #d1d1d6;
  --text-grey: #8e8e93;
  --cool-grey: #708090;
  --text-dark-grey: #6d6d6f;
  --text-darker-grey: #707074;
  --icon-bg-grey: #efeff4;
  --border-gray: #d8d8d8;

  --seo-light-bg-yellow: #fff9e7;
  --light-bg-green: #e5f8e5;
  --dark-text-green: #067519;
  --skill-added-text-bg: #e5f8e5;

  --custom-scrollbar-thumb-bg: var(--theme-yellow);
  --custom-scrollbar-thumb-hover-bg: var(--theme-yellow);

  --section-card-border-radius: 0.5rem;
  --section-card-bg: white;
  --section-card-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  --br-xxxs: 4px;
  --br-xxs: 8px;
  --br-xs: 12px;
  --br-xs: 12px;
  --br-round: 50%;

  --font-xxxs: 8px;
  --font-xxs: 10px;
  --font-xs: 12px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 18px;
  --font-xl: 20px;
  --font-2xl: 22px;
  --font-3xl: 24px;
  --font-4xl: 36px;
  --font-5xl: 42px;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semiBold: 600;
  --font-weight-midBold: 700;
  --font-weight-bold: 900;

  --Primary: #131836;
  --Secondary: #ffcc00;
  --Border: #e4e4e7;
  --Soft-Text: #585d69;
  --breakpoints-xxs: 0px;
  --breakpoints-xs: 300px;
  --breakpoints-sm: 600px;
  --breakpoints-md: 960px;
  --breakpoints-lg: 1280px;
  --breakpoints-xl: 1920px;

  --border-radius-xxxs: 4px;
  --border-radius-xxs: 8px;
  --border-radius-xs: 12px;
  --border-radius-sm: 16px;
  --border-radius-md: 20px;
  --border-radius-lg: 22px;
  --border-radius-xl: 24px;

  --section-gap: 50px;
}
